export const GET_SEARCH_JOB_LIST = 'GET_SEARCH_JOB_LIST';
export const SET_SEARCH_JOB_LIST = 'SET_SEARCH_JOB_LIST';

export const GET_RECENT_SEARCH_LIST = 'GET_RECENT_SEARCH_LIST';
export const SET_RECENT_SEARCH_LIST = 'SET_RECENT_SEARCH_LIST';

export const GET_RECENT_LOCATION_LIST = 'GET_RECENT_LOCATION_LIST';
export const SET_RECENT_LOCATION_LIST = 'SET_RECENT_LOCATION_LIST';

export const GET_JOB_TYPE_LIST = 'GET_JOB_TYPE_LIST';
export const SET_JOB_TYPE_LIST = 'SET_JOB_TYPE_LIST';

export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const SET_JOB_TYPE = 'SET_JOB_TYPE';

export const GET_HOME_JOB_DETAILS = 'GET_HOME_JOB_DETAILS';
export const SET_HOME_JOB_DETAILS = 'SET_HOME_JOB_DETAILS';

export const GET_HOME_SAVE_JOB = 'GET_HOME_SAVE_JOB';
export const SET_HOME_SAVE_JOB = 'SET_HOME_SAVE_JOB';

export const GET_VIEW_NEARBY_JOBS = 'GET_VIEW_NEARBY_JOBS';
export const SET_VIEW_NEARBY_JOBS = 'SET_VIEW_NEARBY_JOBS';
export const RESET_VIEW_NEARBY_JOBS = 'RESET_VIEW_NEARBY_JOBS';

export const GET_JOB_WITH_JOB_TYPE_AND_LATLONG = 'GET_JOB_WITH_JOB_TYPE_AND_LATLONG';
export const SET_JOB_WITH_JOB_TYPE_AND_LATLONG = 'SET_JOB_WITH_JOB_TYPE_AND_LATLONG';

export const POST_HOME_SEARCH_DATA = 'POST_HOME_SEARCH_DATA';
export const SET_HOME_SEARCH_DATA = 'SET_HOME_SEARCH_DATA';
export const RESET_HOME_SEARCH_DATA = 'RESET_HOME_SEARCH_DATA';

export const POST_HOME_APPLY_JOB = 'POST_HOME_APPLY_JOB';
export const SET_HOME_APPLY_JOB = 'SET_HOME_APPLY_JOB';

export const RECALL_HEADER_NOTIFICATION = 'RECALL_HEADER_NOTIFICATION';
export const SET_RECALL_HEADER_NOTIFICATION = 'SET_RECALL_HEADER_NOTIFICATION';

export const FETCH_FAILED = 'FETCH_FAILED';


