import React, { useEffect } from "react";
import storageService from "../../../utils/storageService";
import Carousel from "react-multi-carousel";
import logoyellow from "../../../assets/images/ic-logo-yellow.png";
//@ts-ignore
import Intercom from "intercom-client";

interface Propstype {
  updateSteps: (num: number, data: any) => void;
  step: number;
  history: any;
  showModal: boolean;
  modalUpdateSteps: (data: any) => void;
  callTradeList: () => void;
}

const InitialSignupPage = (props: Propstype) => {
  let window_: any = window;

  useEffect(() => {
    // prefetch();
  }, []);

  useEffect(() => {
    if (window_?.Intercom) {
      window_?.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, [window_]);

  const nextPageHandler = (userType: string) => {
    var user_type = 1;
    if (userType === "builder") {
      user_type = 2;
    }
    props.updateSteps(props.step + 1, { user_type });
    if (user_type === 1) {
      props.callTradeList();
    }
  };

  const guestLoginClicked = (e: any) => {
    e.preventDefault();
    var today = new Date();
    var date =
      today.getFullYear() +
      ":" +
      today.getMonth() +
      ":" +
      today.getDate() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds() +
      ":" +
      today.getMilliseconds();
    storageService.setItem("guestToken", date);
    storageService.setItem("userType", 0);
    props.history.push("/guest-user");
  };

  const phoneViewHandler = (e: any) => {
    e.preventDefault();
    if (props.showModal) {
      props.modalUpdateSteps(0);
      return;
    }
    props.history.push("/login");
  };
  const SLIDER_DATA = [
    {
      imageText: "Find quality work and grow your reputation",
    },
    {
      imageText: "Choose work that suits your location, price, schedule",
    },
    {
      imageText: "Make yourself on what you do best",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="form_wrapper landing_form_wrapper">
      {/* <figure className="signupLogo">
        <img src={logoyellow} alt="logo" />
      </figure> */}
      {/* <Carousel
        responsive={responsive}
        autoPlay={true}
        showDots={true}
        arrows={false}
        infinite={true}
      >
        {SLIDER_DATA.map((item: any, i: number) => {
          return (
            <div key={i}>
              <figure className="banner_img_home">
                <div className="slider_txt">
                  <span>{item.imageText}</span>
                </div>
              </figure>
            </div>
          );
        })}
      </Carousel> */}
      <div
        style={{
          marginTop: "35px",
        }}
      >
        <div className="form_field">
          <button
            className="fill_btn btn-effect"
            onClick={() => nextPageHandler("builder")}
          >
            I’m a builder
          </button>
        </div>
        <div className="form_field">
          <button
            className="fill_grey_btn btn-effect"
            onClick={() => nextPageHandler("tradie")}
          >
            I’m a tradesperson
          </button>
        </div>
        <div
          className="form_field text-center"
          style={{
            marginTop: "60px",
          }}
        >
          <span className="reg">
            Have an account?{" "}
            <a className="link" onClick={phoneViewHandler}>
              Login
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InitialSignupPage;
