
export const EDIT_MILESTONE_ID = 'EDIT_MILESTONE_ID'
export const EDIT_MILESTONE_TIMINGS = 'EDIT_MILESTONE_TIMINGS'
export const EDIT_DETAIL_SCREEN = 'EDIT_DETAIL_SCREEN'
export const FETCH_HOME_BUILDER = 'FETCH_HOME_BUILDER'
export const SET_FETCH_HOME_BUILDER = 'SET_FETCH_HOME_BUILDER'
export const SET_LOCAL_CHANGES = 'SET_LOCAL_CHANGES';
export const GET_LOCAL_CHANGES = 'GET_LOCAL_CHANGES';

export const GET_ACTIVE_JOBS_START = 'GET_ACTIVE_JOBS_START';
export const GET_ACTIVE_JOBS_END = 'GET_ACTIVE_JOBS_END';
export const RESET_ACTIVE_JOBS_START = 'RESET_ACTIVE_JOBS_START';

export const GET_APPLIED_JOBS_START = 'GET_APPLIED_JOBS_START';
export const GET_APPLIED_JOBS_END = 'GET_APPLIED_JOBS_END';
export const RESET_APPLIED_JOBS_START = 'RESET_APPLIED_JOBS_START';

export const GET_PAST_JOBS_START = 'GET_PAST_JOBS_START';
export const GET_PAST_JOBS_END = 'GET_PAST_JOBS_END';
export const RESET_PAST_JOBS_START = 'RESET_PAST_JOBS_START';

export const GET_NEW_JOBS_START = 'GET_NEW_JOBS_START';
export const GET_NEW_JOBS_END = 'GET_NEW_JOBS_END';
export const RESET_NEW_JOBS_START = 'RESET_NEW_JOBS_START';

export const GET_APPROVED_MILESTONE_START = 'GET_APPROVED_MILESTONE_START';
export const GET_APPROVED_MILESTONE_END = 'GET_APPROVED_MILESTONE_END';
export const RESET_APPROVED_MILESTONE_START = 'RESET_APPROVED_MILESTONE_START';

export const GET_MILESTONES_START = 'GET_MILESTONES_START';
export const GET_MILESTONES_END = 'GET_MILESTONES_END';

export const MARK_MILESTONE_COMPLETE = 'MARK_MILESTONE_COMPLETE';

// BUILDER CONST

export const GET_BUILDER_ACTIVE_JOBS = 'GET_BUILDER_ACTIVE_JOBS';  // Active Jobs
export const GET_BUILDER_PAST_JOBS = 'GET_BUILDER_PAST_JOBS'; // Past Jobs
export const GET_BUILDER_OPEN_JOBS = 'GET_BUILDER_OPEN_JOBS'; // Open Jobs
export const GET_BUILDER_NEW_APPLICANTS = 'GET_BUILDER_NEW_APPLICANTS'; // New Applicants
export const GET_BUILDER_NEW_APPROVAL = 'GET_BUILDER_NEW_APPROVAL'; // New Approvals
export const GET_BUILDER_NEW_JOB_APPLICATION_LIST = 'GET_BUILDER_NEW_JOB_APPLICATION_LIST'; // New Job Applicant List
export const GET_BUILDER_JOB_DETAILS = 'GET_BUILDER_JOB_DETAILS'; // Job Details

export const GET_BUILDER_NEW_APPLICANTS_LIST = 'GET_BUILDER_NEW_APPLICANTS_LIST';
export const SET_BUILDER_NEW_APPLICANTS_LIST = 'SET_BUILDER_NEW_APPLICANTS_LIST';

export const SET_BUILDER_ACTIVE_JOBS = 'SET_BUILDER_ACTIVE_JOBS';
export const SET_BUILDER_PAST_JOBS = 'SET_BUILDER_PAST_JOBS';
export const SET_BUILDER_OPEN_JOBS = 'SET_BUILDER_OPEN_JOBS';
export const SET_BUILDER_NEW_APPLICANTS = 'SET_BUILDER_NEW_APPLICANTS'


// Tradie 


export const GET_TRADIE_REVIEWS_LIST_ON_BUILDER = 'GET_TRADIE_REVIEWS_LIST_ON_BUILDER';
export const SET_TRADIE_REVIEWS_LIST_ON_BUILDER = 'SET_TRADIE_REVIEWS_LIST_ON_BUILDER';

export const GET_ACCEPT_DECLINE_TRADIE_REQUEST = 'GET_ACCEPT_DECLINE_TRADIE_REQUEST';
export const SET_ACCEPT_DECLINE_TRADIE_REQUEST = 'SET_ACCEPT_DECLINE_TRADIE_REQUEST';

export const GET_BUILDER_NEW_APPROVAL_LIST = 'GET_BUILDER_NEW_APPROVAL_LIST';
export const SET_BUILDER_NEW_APPROVAL_LIST = 'SET_BUILDER_NEW_APPROVAL_LIST';


export const GET_CLEAR_JOBS = 'GET_CLEAR_JOBS';
export const SET_CLEAR_JOBS = 'SET_CLEAR_JOBS';