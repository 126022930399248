import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import logoyellow from "../../assets/images/ic-logo-yellow.png";
import bannerimage1 from "../../assets/images/onboarding-banner-1.jpg";
import bannerimage2 from "../../assets/images/onboarding-banner-2.jpg";
import bannerimage3 from "../../assets/images/onboarding-banner-3.jpg";

import bannerimage11 from "../../assets/images/modal-slider-bg-1.png";
import bannerimage22 from "../../assets/images/modal-slider-bg-2.png";
import bannerimage33 from "../../assets/images/modal-slider-bg-3.png";
import singUpImage from "../../assets/images/tickt-signup.jpg";

interface Props {
  type: string;
  history: any;
  showModal: boolean | undefined;
  modalUpdateSteps: (data: any) => void;
  setShowModal: (data: any) => void;
  setSocialData: (data: any) => void;
}

const DATA: any = {
  login: {
    title: "Have an account? ",
    button: "Log In",
    nav: "login",
  },
  signup: {
    title: "Don’t have an account?",
    button: "Sign up",
    nav: "signup",
  },
};

const AuthSlider = (props: Props) => {
  const SLIDER_DATA = [
    {
      image: props.showModal ? bannerimage11 : bannerimage1,
      imageText: "Find quality work and grow your reputation",
    },
    {
      image: props.showModal ? bannerimage22 : bannerimage2,
      imageText: "Choose work that suits your location, price, schedule",
    },
    {
      image: props.showModal ? bannerimage33 : bannerimage3,
      imageText: "Make yourself on what you do best",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const data = DATA[props.type];

  const onLoginSignupClicked = (e: any) => {
    e.preventDefault();
    if (props.showModal) {
      if (data.nav === "login") {
        props.modalUpdateSteps(0);
        return;
      } else {
        props.modalUpdateSteps(2);
        props.setSocialData("");
        return;
      }
    }
    props.history.push(`/${data.nav}`);
  };

  return (
    <figure className="banner_img">
      <img src={singUpImage} alt="banner-img" />
    </figure>
  );
};

export default AuthSlider;
