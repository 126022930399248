export const GET_TRADIE_PROFILE_DATA = 'GET_TRADIE_PROFILE_DATA';
export const SET_TRADIE_PROFILE_DATA = 'SET_TRADIE_PROFILE_DATA';

export const GET_TRADIE_PROFILE_VIEW = 'GET_TRADIE_PROFILE_VIEW';
export const SET_TRADIE_PROFILE_VIEW = 'SET_TRADIE_PROFILE_VIEW';
export const CLEAN_TRADIE_PROFILE_VIEW_DATA = 'CLEAN_TRADIE_PROFILE_VIEW_DATA';

export const GET_BUILDER_PROFILE_VIEW = 'GET_BUILDER_PROFILE_VIEW';
export const SET_BUILDER_PROFILE_VIEW = 'SET_TBUILDERPROFILE_VIEW';

export const GET_TRADIE_BASIC_DETAILS = 'GET_TRADIE_BASIC_DETAILS';
export const SET_TRADIE_BASIC_DETAILS = 'SET_TRADIE_BASIC_DETAILS';
export const CLEAN_TRADIE_BASIC_DETAILS = 'CLEAN_TRADIE_BASIC_DETAILS';

export const GET_BANK_DETAILS_START = 'GET_BANK_DETAILS_START';
export const GET_BANK_DETAILS_END = 'GET_BANK_DETAILS_END';

export const ADD_BANK_DETAILS_START = 'ADD_BANK_DETAILS_START';
export const ADD_BANK_DETAILS_END = 'ADD_BANK_DETAILS_END';

export const UPDATE_BANK_DETAILS_START = 'UPDATE_BANK_DETAILS_START';
export const UPDATE_BANK_DETAILS_END = 'UPDATE_BANK_DETAILS_END';

export const REMOVE_BANK_DETAILS_START = 'REMOVE_BANK_DETAILS_START';
export const REMOVE_BANK_DETAILS_END = 'REMOVE_BANK_DETAILS_END';


export const GET_TRADIE_PROFILE = 'GET_TRADIE_PROFILE';
export const SET_TRADIE_PROFILE = 'SET_TRADIE_PROFILE';

export const GET_PROFILE_BUILDER = 'GET_PROFILE_BUILDER'
export const SET_PROFILE_BUILDER = 'SET_PROFILE_BUILDER'

export const GET_SAVED_JOBS = 'GET_SAVED_JOBS';
export const SET_SAVED_JOBS = 'SET_SAVED_JOBS';

export const GET_SETTINGS = 'GET_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';

export const SET_SEARCHING = 'SET_SEARCHING';

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const SET_PAYMENT_HISTORY = 'SET_PAYMENT_HISTORY';

export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';

export const GET_BANK_DETAILS_START_BUILDER = 'GET_BANK_DETAILS_START_BUILDER';
export const GET_BANK_DETAILS_END_BUILDER = 'GET_BANK_DETAILS_END_BUILDER';

export const ADD_BANK_DETAILS_START_BUILDER = 'ADD_BANK_DETAILS_START_BUILDER'
export const ADD_BANK_DETAILS_END_BUILDER = 'ADD_BANK_DETAILS_END_BUILDER';


export const UPDATE_BANK_DETAILS_START_BUILDER = 'UPDATE_BANK_DETAILS_START_BUILDER'
export const UPDATE_BANK_DETAILS_END_BUILDER = 'UPDATE_BANK_DETAILS_END_BUILDER';

